import React, { useState, useCallback, useMemo } from "react";
import { Paper, Typography, Box, Chip, Tooltip, Alert } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

const FileUploadComponent = ({
  onFileUpload,
  onAnalysisComplete,
  ws,
  isConnected,
  customDropzoneText,
  analyzedData, // Add this prop
  acceptedFileTypes = {
    "application/pdf": [".pdf"],
    "text/plain": [".txt"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
  },
}) => {
  // Remove the local analyzedData state since it's now managed by parent
  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
    error: null,
  });

  // Handle file drop
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      setFileState((prev) => ({
        ...prev,
        file,
        uploadStatus: "File selected",
        error: null,
      }));

      // Notify parent component
      onFileUpload(file);

      // Only proceed with analysis if websocket is connected
      if (ws && isConnected) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const formDataToSend = {
            formType: "extract_insights",
            fileContent: e.target.result,
            fileName: file.name,
          };

          try {
            ws.send(JSON.stringify(formDataToSend));
          } catch (error) {
            setFileState((prev) => ({
              ...prev,
              error: "Failed to send file for analysis. Please try again.",
            }));
          }
        };

        reader.onerror = () => {
          setFileState((prev) => ({
            ...prev,
            error: "Failed to read file. Please try again.",
          }));
        };

        reader.readAsDataURL(file);
      }
    },
    [ws, isConnected, onFileUpload]
  );

  // Setup dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    multiple: false,
    maxSize: 10485760, // 10MB limit
  });

  // Memoized dropzone component
  const DropzoneContent = useMemo(
    () => (
      <Paper
        {...getRootProps()}
        elevation={3}
        className="upload-dropzone"
        sx={{
          p: 3,
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : isDragActive ? "grey.500" : "background.paper",
          mb: 3,
          border: "2px dashed",
          borderColor: isDragActive ? "primary.main" : "grey.300",
          "&:hover": {
            borderColor: "primary.main",
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "grey.700" : "grey.300",
          },
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon sx={{ fontSize: 48, mb: 1, color: "primary.main" }} />
        <Typography variant="h6">
          {isDragActive
            ? "Drop the file here"
            : customDropzoneText ||
              "Drag 'n' drop a file here, or click to select a file"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Accepted files: PDF, TXT, DOCX (Max size: 10MB)
        </Typography>
      </Paper>
    ),
    [getRootProps, getInputProps, isDragActive, customDropzoneText]
  );

  return (
    <Box>
      {DropzoneContent}

      {fileState.error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {fileState.error}
        </Alert>
      )}

      {fileState.file && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">
            Selected file: {fileState.file.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Size: {Math.round(fileState.file.size / 1024)} KB
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileUploadComponent;
