import React from 'react';
import { Container, Box, Typography, Paper } from '@mui/material';

const Disclaimer = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Legal Disclaimer
        </Typography>
        
        <Paper elevation={2} sx={{ p: 4, mt: 4 }} className='papercard'>
          <Typography variant="h5" gutterBottom>
            Introduction
          </Typography>
          <Typography variant="body1" paragraph>
            This platform is designed to assist educators, instructional designers, and higher education professionals in generating comprehensive, outcome-based assessments through the use of advanced artificial intelligence technologies. By using this platform, you acknowledge and agree to the terms and conditions set forth in this disclaimer.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            AI Technology Usage Notice
          </Typography>
          <Typography variant="body1" paragraph>
            Our application leverages state-of-the-art AI technologies provided by third-party suppliers, including but not limited to Google Gemini, Anthropic Claude, and OpenAI's Large Language Models (LLMs). These AI tools are employed to help create assessment materials intended for educational purposes.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Generated Content Disclaimer
          </Typography>
          <Typography variant="body1" paragraph>
            All assessments, instructional materials, and content generated by the platform are produced by AI systems and are not created by human educators or experts. While we endeavor to deliver high-quality, relevant, and accurate outputs, please note the following:
          </Typography>
          <Typography variant="body1" paragraph>
            Potential Inaccuracies: AI-generated content may contain factual errors, inaccuracies, ambiguities, or outdated information.
          </Typography>
          <Typography variant="body1" paragraph>
            Quality Variability: The depth, comprehensiveness, and alignment to learning outcomes may vary.
          </Typography>
          <Typography variant="body1" paragraph>
            Human Review Essential: All AI-generated assessments must be carefully reviewed, validated, and if necessary, revised by qualified educators, instructional designers, or subject matter experts before being integrated into coursework, distributed to learners, or used for official assessments.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            No Warranty
          </Typography>
          <Typography variant="body1" paragraph>
            This platform and any AI-generated content are provided strictly on an "as is" basis. We make no representations or warranties of any kind, either express or implied, regarding the following:
          </Typography>
          <Typography variant="body1" paragraph>
            Accuracy or Completeness: We do not guarantee the correctness, completeness, or timeliness of AI-generated materials.
          </Typography>
          <Typography variant="body1" paragraph>
            Fitness for Purpose: We disclaim any implied warranties of suitability for a particular educational goal or accreditation requirement.
          </Typography>
          <Typography variant="body1" paragraph>
            Reliability and Availability: While we strive to maintain a stable and continuous service, we cannot guarantee uninterrupted access or error-free performance.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Academic and Accreditation Considerations
          </Typography>
          <Typography variant="body1" paragraph>
            The content generated by the platform may not conform to specific curriculum standards, accreditation requirements, or institutional policies. Users are solely responsible for ensuring that the final assessments and materials comply with relevant academic, institutional, legal, and professional guidelines.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Intellectual Property Rights
          </Typography>
          <Typography variant="body1" paragraph>
            Ownership of AI Models: The underlying AI technologies and models are owned by their respective providers (e.g., Google, Anthropic, OpenAI) and are used under license.
          </Typography>
          <Typography variant="body1" paragraph>
            User Input Rights: Users retain ownership of any original content, prompts, or data they input into the platform.
          </Typography>
          <Typography variant="body1" paragraph>
            Generated Content Usage: Ownership and rights over AI-generated materials are subject to the terms of service of the respective AI providers. Users are advised to consult applicable terms to understand permissible uses, intellectual property implications, and licensing restrictions.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Data Usage and Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Third-Party Processing: User inputs may be transmitted, processed, or stored by external AI service providers.
          </Typography>
          <Typography variant="body1" paragraph>
            Compliance with Privacy Laws: We are committed to handling data in accordance with applicable privacy laws and regulations.
          </Typography>
          <Typography variant="body1" paragraph>
            Review Provider Policies: Users should review the privacy policies and data handling practices of Google, Anthropic, and OpenAI to understand how these third parties process and store information.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            Under no circumstances shall we be liable for any damages arising out of or related to the use of this platform or its AI-generated content, including but not limited to:
          </Typography>
          <Typography variant="body1" paragraph>
            Content Accuracy: Any errors, omissions, or inaccuracies in the AI-generated assessments.
          </Typography>
          <Typography variant="body1" paragraph>
            Educational Outcomes: Any adverse educational, accreditation, or institutional consequences stemming from the use of these materials.
          </Typography>
          <Typography variant="body1" paragraph>
            Damages: Direct, indirect, incidental, consequential, or punitive damages related to the platform's use, content, or performance.
          </Typography>
          <Typography variant="body1" paragraph>
            Technical Issues: Any service interruptions, delays, or losses of data resulting from technical failures or maintenance activities.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            User Responsibilities
          </Typography>
          <Typography variant="body1" paragraph>
            By using this platform, users agree to the following:
          </Typography>
          <Typography variant="body1" paragraph>
            Thorough Review: Critically evaluate all AI-generated content, modifying or discarding it as necessary to meet academic standards.
          </Typography>
          <Typography variant="body1" paragraph>
            Compliance: Ensure usage aligns with institutional policies, educational best practices, accreditation requirements, and applicable laws.
          </Typography>
          <Typography variant="body1" paragraph>
            Reporting Issues: Promptly report any inappropriate, biased, or factually incorrect content so that improvements can be made.
          </Typography>
          <Typography variant="body1" paragraph>
            Maintaining Academic Integrity: Uphold the principles of honest scholarship and proper attribution when incorporating generated materials into coursework.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Changes to Service and Disclaimer
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify, update, or discontinue any aspect of the platform at any time. This may include changing the AI models used, altering platform features, or updating this disclaimer. Users will be notified of such changes through official communication channels or platform notices. Continued use of the platform following these modifications constitutes acceptance of the updated terms.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            This disclaimer, and any disputes arising from it or the use of this platform, shall be governed by and construed in accordance with the laws and regulations applicable in the jurisdiction in which the platform is operated, without regard to conflicts of law principles.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Acknowledgment
          </Typography>
          <Typography variant="body1" sx={{ mt: 4 }} paragraph>
            By accessing or using this platform, you confirm that you have read, understood, and agree to abide by the terms, conditions, and limitations outlined in this disclaimer.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Disclaimer;
