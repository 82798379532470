import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Link, 
  Box, 
  Paper,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth } from './useAuth';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login, loginWithGoogle } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(email, password);
      navigate('/select');
    } catch (error) {
      let errorMessage = 'Login failed. Please check your credentials and try again.';
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      setError(errorMessage);
    }
  };

  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        backgroundColor: 'background.default'
      }}
    >
      <Paper 
        elevation={6}
        sx={{
          p: 4,
          width: '100%',
          maxWidth: 400,
          borderRadius: 2
        }}
      >
        <Box 
          component="form" 
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 3 }}>
            <img src="/logo.png" alt="Logo" width="100" style={{ marginBottom: '16px' }} />
            <Typography variant="h5" component="h1" gutterBottom>
              Assessment Partner
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Sign in to continue to Assessment Partner
            </Typography>
          </Box>

          {error && (
            <Typography 
              color="error" 
              sx={{ 
                mb: 2,
                backgroundColor: 'error.light',
                color: 'error.main',
                p: 1,
                borderRadius: 1,
                fontSize: '0.875rem'
              }}
            >
              {error}
            </Typography>
          )}

          <TextField
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon color="action" />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            size="large"
            sx={{ 
              mt: 2,
              py: 1.5,
              textTransform: 'none',
              fontSize: '1rem'
            }}
          >
            Sign In
          </Button>

          <GoogleLogin
            onSuccess={async (credentialResponse) => {
              try {
                await loginWithGoogle(credentialResponse.credential);
                navigate('/select');
              } catch (error) {
                setError('Google login failed. Please try again.');
              }
            }}
            onError={() => {
              setError('Google login failed. Please try again.');
            }}
          />

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Link 
              component={RouterLink} 
              to="/forgot-password" 
              variant="body2"
              sx={{ textDecoration: 'none' }}
            >
              Forgot Password?
            </Link>
            <Link 
              component={RouterLink} 
              to="/create-account" 
              variant="body2"
              sx={{ textDecoration: 'none' }}
            >
              Create Account
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default function LoginPageWithGoogle() {
  return (
    <GoogleOAuthProvider clientId="706230691850-6j8f3jmde76ju44sb9agv4deepftcr3e.apps.googleusercontent.com">
      <LoginPage />
    </GoogleOAuthProvider>
  );
}
