import React from "react";
import { Box, Typography } from "@mui/material";

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.paper",
        py: 3,
        mt: "auto", // This pushes the footer to the bottom
        width: "100%", // Ensures the footer spans the full width
      }}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Assessment Partner | McMaster University -
        MacPherson Institute | EdustackAI
      </Typography>
    </Box>
  );
}

export default Footer;
