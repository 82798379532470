import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Autocomplete,
  Tooltip,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "../config";
import MarkdownRenderer from "./MarkdownRenderer";
import QueueStatus from "./QueueStatus";
import ConfirmationModal from "./ConfirmationModal";
import { LoadingIndicator } from "./CustomHooks";

function SimpleForm() {
  const navigate = useNavigate();
  const resultContentsRef = useRef(null);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Form Data State
  const [formData, setFormData] = useState({
    userLevels: [],
    mainFields: [],
    assignmentGroups: {},
  });

  // Selected Values State
  const [selectedValues, setSelectedValues] = useState({
    assignments: [],
    userLevel: "",
    mainField: "",
    TopicField: "",
  });

  // UI State
  const [uiState, setUiState] = useState({
    messages: [],
    loading: false,
    resultContents: "",
    visibleContentLength: 0,
    showSaveButton: false,
    saveButtonDisabled: false,
    token: null,
    showPrintButton: false,
    showCustomizeButton: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
    showConfirmationModal: false,
    queuePosition: 0,
    isQueued: false,
  });

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setUiState((prev) => ({
          ...prev,
          messages: [data.message],
          queuePosition: data.queuePosition,
          loading: true,
          isQueued: true,
        }));
        break;

      case "processing":
        const statusMessage =
          data.message ||
          (data.status === "processing" && data.message) ||
          "Processing...";
        setUiState((prev) => ({
          ...prev,
          messages: [statusMessage],
          loading: true,
          isQueued: false,
        }));
        break;

      case "Success":
        setUiState((prev) => ({
          ...prev,
          resultContents: data.content,
          loading: false,
          token: data.token,
          messages: [],
        }));
        break;

      case "done":
        setUiState((prev) => ({
          ...prev,
          showSaveButton: true,
          showPrintButton: true,
          showCustomizeButton: true,
          loading: false,
          messages: [],
        }));
        break;

      default:
        if (data.error) {
          setUiState((prev) => ({
            ...prev,
            snackbarMessage: "An error occurred",
            snackbarSeverity: "error",
            snackbarOpen: true,
            loading: false,
            messages: [],
          }));
        }
    }
  }, []);

  // Initialize WebSocket
  useEffect(() => {
    const initWebSocket = () => {
      const wsInstance = new WebSocket(`${config.appUrl}/ws`);

      wsInstance.onopen = () => {
        setIsConnected(true);
        setUiState((prev) => ({
          ...prev,
          error: null,
          snackbarOpen: false,
        }));
      };

      wsInstance.onmessage = handleWebSocketMessage;

      wsInstance.onclose = (event) => {
        setIsConnected(false);

        // Check if the closure was clean
        if (!event.wasClean) {
          setUiState((prev) => ({
            ...prev,
            loading: false,
            snackbarMessage: "Connection lost. Please try again.",
            snackbarSeverity: "error",
            snackbarOpen: true,
          }));
        }

        // Attempt to reconnect unless the page is being unloaded
        if (!window.isUnloading) {
          setTimeout(initWebSocket, 3000);
        }
      };

      wsInstance.onerror = (error) => {
        console.error("WebSocket error:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Connection error. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      };

      setWs(wsInstance);
    };

    initWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [handleWebSocketMessage]);

  // Page unload handler
  useEffect(() => {
    window.isUnloading = false;

    const handleBeforeUnload = () => {
      window.isUnloading = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.isUnloading = true;
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  // Scroll to bottom effect
  useEffect(() => {
    if (uiState.visibleContentLength > 0 && resultContentsRef.current) {
      const scrollToBottom = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const currentPosition = window.pageYOffset;
        const targetPosition = scrollHeight - window.innerHeight;
        const distance = targetPosition - currentPosition;
        const duration = 600;

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percentage = Math.min(progress / duration, 1);

          window.scrollTo(0, currentPosition + distance * percentage);

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };

        let start = null;
        window.requestAnimationFrame(step);
      };

      scrollToBottom();
    }
  }, [uiState.visibleContentLength]);

  // Content reveal animation
  useEffect(() => {
    if (uiState.resultContents.length > 20) {
      const revealSpeed = 12;
      const intervalId = setInterval(() => {
        setUiState((prev) => ({
          ...prev,
          visibleContentLength: Math.min(
            prev.visibleContentLength + revealSpeed,
            prev.resultContents.length
          ),
        }));
      }, 10);

      return () => clearInterval(intervalId);
    }
  }, [uiState.resultContents]);

  // Fetch initial form data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/api/data`);
        const data = await response.json();
        setFormData({
          userLevels: data.user_level,
          mainFields: data.main_field,
          assignmentGroups: {
            Components: data.assignments.Componets,
          },
        });
      } catch (error) {
        setUiState((prev) => ({
          ...prev,
          error: "Failed to load form data",
          snackbarOpen: true,
          snackbarMessage: "Failed to load form data",
          snackbarSeverity: "error",
        }));
      }
    };
    fetchData();
  }, []);

  // Form submission handler
  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();

      if (!ws || !isConnected) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "No connection available. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        return;
      }

      try {
        const formDataToSend = {
          assignmentTypes: selectedValues.assignments.map(
            (assignment) => assignment.optionValue
          ),
          userLevel: selectedValues.userLevel,
          mainField: selectedValues.mainField,
          TopicField: selectedValues.TopicField,
          TypeOfAssessments: "none",
          skillField: "none",
          genAiUse: "none",
          gptVersion: "gpt-4-1106-preview",
          formType: "simple",
        };

        ws.send(JSON.stringify(formDataToSend));
        setUiState((prev) => ({ ...prev, loading: true }));
      } catch (error) {
        console.error("Error sending data:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Failed to send request. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    },
    [ws, isConnected, selectedValues]
  );

  const handleGenerateClick = React.useCallback(
    (event) => {
      event.preventDefault();

      if (uiState.resultContents) {
        setUiState((prev) => ({
          ...prev,
          showConfirmationModal: true,
        }));
      } else {
        handleSubmit(event);
      }
    },
    [uiState.resultContents, handleSubmit]
  );

  const handleConfirmGenerate = React.useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showConfirmationModal: false,
      loading: false,
      messages: [],
      resultContents: "",
      visibleContentLength: 0,
      showSaveButton: false,
      showPrintButton: false,
      showCustomizeButton: false,
      token: null,
    }));

    handleSubmit({
      preventDefault: () => {},
    });
  }, [handleSubmit]);

  const handleCancelGenerate = React.useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showConfirmationModal: false,
    }));
  }, []);

  // Handle save and share
  const handleSaveAndShare = async () => {
    try {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: true }));
      const response = await fetch(`${config.webUrl}/assessments/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: uiState.token }),
      });

      if (!response.ok) throw new Error("Failed to save assessment");

      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Assessment saved successfully",
        snackbarSeverity: "success",
        snackbarOpen: true,
      }));
    } catch (error) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to save assessment",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    } finally {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: false }));
    }
  };

  // Handle customize
  const handleCustomize = async () => {
    if (uiState.token) {
      try {
        await handleSaveAndShare();
        navigate(`/assessments/list/${uiState.token}`);
      } catch (error) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and customize",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    }
  };

  // Handle print
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      MarkdownRenderer.getPrintContent(uiState.resultContents)
    );
    printWindow.document.close();
    printWindow.print();
  };

  const LoadingComponent = useMemo(
    () => (
      <Box>
        {uiState.loading && <LoadingIndicator messages={uiState.messages} />}
      </Box>
    ),
    [uiState.loading, uiState.messages]
  );

  // Render form fields (memoized)
  const renderFormFields = useMemo(
    () => (
      <>
        <Tooltip
          title="Select one or more assessment components"
          placement="right"
        >
          <FormControl fullWidth margin="normal">
            <Autocomplete
              multiple
              options={Object.entries(formData.assignmentGroups).flatMap(
                ([group, items]) => items
              )}
              value={selectedValues.assignments}
              onChange={(event, newValue) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  assignments: newValue,
                }));
              }}
              getOptionLabel={(option) => option.optionText}
              renderInput={(params) => (
                <TextField {...params} label="Select Assessment Components" />
              )}
              disabled={uiState.loading}
            />
          </FormControl>
        </Tooltip>

        <FormControl fullWidth margin="normal">
          <InputLabel>Level of study</InputLabel>
          <Select
            value={selectedValues.userLevel}
            onChange={(e) =>
              setSelectedValues((prev) => ({
                ...prev,
                userLevel: e.target.value,
              }))
            }
            label="Select User Level"
            disabled={uiState.loading}
          >
            {formData.userLevels.map((item) => (
              <MenuItem value={item.optionValue} key={item.optionValue}>
                {item.optionText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <Tooltip
            title="Select a Discipline. This field is searchable."
            placement="right"
          >
            <Autocomplete
              options={formData.mainFields}
              getOptionLabel={(option) => option.optionText}
              value={formData.mainFields.find(
                (option) => option.optionValue === selectedValues.mainField
              )}
              onChange={(event, newValue) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  mainField: newValue ? newValue.optionValue : "",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select a Discipline" />
              )}
              disabled={uiState.loading}
            />
          </Tooltip>
        </FormControl>

        {selectedValues.mainField &&
          selectedValues.mainField !== "other" &&
          selectedValues.mainField !== "none" && (
            <Tooltip
              title="Enter a topic within the selected discipline (optional)"
              placement="right"
            >
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Topic within the discipline (optional)"
                  variant="outlined"
                  value={selectedValues.TopicField}
                  onChange={(e) =>
                    setSelectedValues((prev) => ({
                      ...prev,
                      TopicField: e.target.value,
                    }))
                  }
                  disabled={uiState.loading}
                />
              </FormControl>
            </Tooltip>
          )}
      </>
    ),
    [formData, selectedValues, uiState.loading]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <form onSubmit={handleGenerateClick}>
            {renderFormFields}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={uiState.loading}
              >
                Generate Assessment
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="h5" component="h4">
              Select one or more assessment components and a discipline,
              including an optional topic if desired, along with the level of
              study to generate a customized assessment.
            </Typography>
          </div>

          {LoadingComponent}
        </Grid>

        {uiState.isQueued && (
          <Grid item xs={12}>
            <QueueStatus
              queuePosition={uiState.queuePosition}
              message={uiState.messages[0]}
            />
          </Grid>
        )}
      </Grid>

      {(uiState.loading || uiState.resultContents) && (
        <Box>
          <Box ref={resultContentsRef} mt={2} className="resultsBox">
            <MarkdownRenderer
              content={uiState.resultContents.slice(
                0,
                uiState.visibleContentLength
              )}
            />
          </Box>

          {uiState.showSaveButton && (
            <Box mt={2} display="flex" gap={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
                disabled={uiState.saveButtonDisabled}
              >
                Save and Share
              </Button>

              {uiState.showCustomizeButton && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCustomize}
                  disabled={!uiState.token}
                >
                  Customize
                </Button>
              )}

              {uiState.showPrintButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}

      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
          severity={uiState.snackbarSeverity}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmationModal
        open={uiState.showConfirmationModal}
        onConfirm={handleConfirmGenerate}
        onCancel={handleCancelGenerate}
      />
    </>
  );
}

export default SimpleForm;
