import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Snackbar,
  TextField,
  Chip,
  Switch,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import MCQVerification from "./MCQVerification";
import config from "../config";
import { LoadingIndicator } from "./CustomHooks";
import { useAuth } from "./useAuth";
import DeleteIcon from '@mui/icons-material/Delete';

function MCQGenerator() {
  const { user } = useAuth();

  // MCQ generation states
  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  const [numberOfAnswers, setNumberOfAnswers] = useState(4);
  const [difficulty, setDifficulty] = useState("medium");
  const [userLevels, setUserLevels] = useState([]);
  const [mainFields, setMainFields] = useState([]);
  const [selectedUserLevel, setSelectedUserLevel] = useState("");
  const [selectedMainField, setSelectedMainField] = useState("");
  const [topicField, setTopicField] = useState("");
  const [skillField, setSkillField] = useState("");
  const [taxonomyModels, setTaxonomyModels] = useState({});
  const [selectedTaxonomyModel, setSelectedTaxonomyModel] = useState("");
  const [modelInfo, setModelInfo] = useState("");
  const [includeIllustrations, setIncludeIllustrations] = useState(false);

  // UI states
  const [loading, setLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showPrintButton, setShowPrintButton] = useState(false);
  const [messages, setMessages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [fileId, setFileId] = useState(null);
  const [ws, setWs] = useState(null);

  const handleNumberOfAnswersChange = (event) => {
    setNumberOfAnswers(event.target.value);
  };

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setMessages([data.message]);
        setLoading(true);
        break;

      case "processing":
        const statusMessage = data.message || "Processing...";
        setMessages([statusMessage]);
        setLoading(true);
        break;

      case "Success":
        if (data.file_id) {
          setFileId(data.file_id);
          fetchMCQ(data.file_id);
          setLoading(false);
        }
        break;

      case "done":
        setShowSaveButton(true);
        setShowPrintButton(true);
        setLoading(false);
        setMessages([]);
        break;

      default:
        if (data.error) {
          console.error("Error:", data.error);
          setSnackbarMessage("An error occurred");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setLoading(false);
          setMessages([]);
        }
    }
  }, []);

  // Initialize WebSocket
  useEffect(() => {
    const WS = new WebSocket(`${config.appUrl}/ws`);
    WS.onmessage = handleWebSocketMessage;
    setWs(WS);

    return () => {
      if (WS && WS.readyState === WebSocket.OPEN) {
        WS.close();
      }
    };
  }, [handleWebSocketMessage]);

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();
      setUserLevels(data.user_level);
      setMainFields(data.main_field);
      setTaxonomyModels(data.taxonomies);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbarMessage("Error fetching data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const fetchMCQ = async (id) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}`);
      if (!response.ok) throw new Error("Failed to fetch MCQ data");
      const mcqData = await response.json();
      setQuestions(mcqData.questions);
    } catch (error) {
      console.error("Error fetching MCQ:", error);
      setSnackbarMessage("Error fetching MCQ data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessages(["Generating MCQs..."]);

    const formData = {
      numberOfQuestions,
      numberOfAnswers,
      difficulty,
      userLevel: selectedUserLevel,
      mainField: selectedMainField,
      topic: topicField,
      skillField,
      taxonomyModel: selectedTaxonomyModel,
      formType: includeIllustrations ? "mcq_illustration" : "mcq",
    };

    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log("Sending form data:", formData);
      ws.send(JSON.stringify(formData));
    } else {
      setSnackbarMessage("WebSocket connection not available");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSaveAndShare = async () => {
    if (!fileId) {
      setSnackbarMessage("No MCQs to save");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${config.webUrl}/mcq/save-and-share/${fileId}`,
        {
          method: "POST",
        }
      );
      if (!response.ok) throw new Error("Failed to save MCQs");

      setSnackbarMessage("MCQs saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving MCQs:", error);
      setSnackbarMessage("Failed to save MCQs");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const verifyQuestion = async (index) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [index]: { status: "verifying" },
    }));
    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: questions[index].question,
          selected_answer:
            questions[index].options[questions[index].correctAnswer],
        }),
      });
      const data = await response.json();
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: data.result,
          explanation: data.explanation,
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: "re-verify",
          explanation:
            "An error occurred during verification. Please try again.",
        },
      }));
    }
  };

  const handleNumberOfQuestionsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumberOfQuestions(value);
    if (value > 10) {
      setModelInfo(
        "Generating more than 10 questions may take longer due to the verification and overlap checking process."
      );
    } else {
      setModelInfo("");
    }
  };

  const handlePrint = useCallback(() => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print MCQs</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .question { margin-bottom: 20px; }
            .options { margin-left: 20px; }
            .reason { font-style: italic; color: #666; }
          </style>
        </head>
        <body>
          ${questions
            .map(
              (q, index) => `
              <div class="question">
                <h3>${index + 1}. ${q.question}</h3>
                <div class="options">
                  ${q.options
                    .map(
                      (option, i) => `
                      <p>${String.fromCharCode(65 + i)}. ${option}</p>
                    `
                    )
                    .join("")}
                </div>
                <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                  65 + q.correctAnswer
                )}</p>
                <p class="reason"><strong>Explanation:</strong> ${q.reason}</p>
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  }, [questions]);

  const toggleAnswers = () => setShowAnswers(!showAnswers);

  const renderMessage = (content) => (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({ node, ...props }) => <Typography paragraph {...props} />,
        h3: ({ node, ...props }) => (
          <Typography variant="h6" gutterBottom {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ marginBottom: "10px" }} {...props} />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleRemoveQuestion = async (index) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${fileId}/remove-question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questionIndex: index }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove question');
      }

      // Update local state
      setQuestions(prevQuestions => prevQuestions.filter((_, i) => i !== index));
      setSnackbarMessage('Question removed successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error removing question:', error);
      setSnackbarMessage('Failed to remove question');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <h1 className="text-3xl font-bold my-4">MCQ Generation</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <Tooltip
                title="Select a Discipline. This field is searchable."
                placement="right"
              >
                <Autocomplete
                  options={mainFields}
                  getOptionLabel={(option) => option.optionText}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a Discipline" />
                  )}
                  value={
                    mainFields.find(
                      (option) => option.optionValue === selectedMainField
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedMainField(newValue ? newValue.optionValue : "");
                  }}
                />
              </Tooltip>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="user-level-label">Level of study</InputLabel>
              <Select
                labelId="user-level-label"
                id="userLevels"
                value={selectedUserLevel}
                label="Select User Level"
                onChange={(e) => setSelectedUserLevel(e.target.value)}
              >
                {userLevels.map((item) => (
                  <MenuItem value={item.optionValue} key={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedMainField !== "other" &&
              selectedMainField !== "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="Topic_field"
                    label="Topic within the discipline"
                    variant="outlined"
                    value={topicField}
                    onChange={(e) => setTopicField(e.target.value)}
                    required
                  />
                </FormControl>
              )}

            {selectedMainField !== "other" &&
              selectedMainField === "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="skills_field"
                    label="Skills | Competencies | Specialization"
                    variant="outlined"
                    value={skillField}
                    onChange={(e) => setSkillField(e.target.value)}
                    required
                  />
                </FormControl>
              )}

            <FormControl fullWidth margin="normal">
              <InputLabel id="taxonomy-model-label">
                Learning Taxonomy or Model
              </InputLabel>
              <Select
                labelId="taxonomy-model-label"
                id="taxonomyModels"
                value={selectedTaxonomyModel}
                label="Learning Taxonomy or Model"
                onChange={(e) => setSelectedTaxonomyModel(e.target.value)}
                required
              >
                {Object.entries(taxonomyModels).map(([group, items]) => [
                  <MenuItem
                    value={group}
                    key={group}
                    disabled
                    style={{ fontWeight: "bold" }}
                  >
                    {group}
                  </MenuItem>,
                  ...items.map((item) => (
                    <MenuItem
                      value={item.optionValue}
                      key={item.optionValue}
                      style={{ paddingLeft: "20px" }}
                    >
                      {item.optionText}
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Number of Questions</InputLabel>
              <Select
                value={numberOfQuestions}
                onChange={handleNumberOfQuestionsChange}
                label="Number of Questions"
                required
              >
                {(includeIllustrations ? [3, 5, 10] : [5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60]).map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Number of answer options</InputLabel>
              <Select
                value={numberOfAnswers}
                onChange={handleNumberOfAnswersChange}
                label="Number of answer options"
                required
              >
                {[3, 4, 5, 6].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Level of difficulty</InputLabel>
              <Select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                label="Level of difficulty"
                required
              >
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>

            {modelInfo && (
              <Alert severity="info" style={{ marginTop: "10px" }}>
                {modelInfo}
              </Alert>
            )}

            <Box display="flex" alignItems="center" gap={2} sx={{ mt: 2 }}>
              {user?.group === "admin" && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeIllustrations}
                      onChange={(e) => setIncludeIllustrations(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Include Illustrations"
                />
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Generate MCQs
              </Button>
            </Box>
          </form>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="body1">
              <Typography variant="h5">
                Generate targeted multiple-choice questions:
              </Typography>
              <ul>
                <li>Select your discipline and study level</li>
                <li>Choose a specific topic or skill to focus on</li>
                <li>Select a learning taxonomy and target level</li>
                <li>
                  Specify the number of questions and difficulty level
                </li>
              </ul>
            </Typography>
          </div>
          {loading && <LoadingIndicator messages={messages} />}
        </Grid>
      </Grid>

      {questions.length > 0 && (
        <Box mt={4}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleAnswers}
              startIcon={
                showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              sx={{ ml: 1 }}
            >
              {showAnswers ? "Hide Answers" : "Show Answers"}
            </Button>
          </Box>

          {questions.map((q, index) => (
            <Card key={index} sx={{ mb: 2, position: 'relative' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Question {index + 1} of {questions.length}
                </Typography>
                {q.illustration && (
                  <Box 
                    sx={{ 
                      mb: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      '& svg': {
                        maxWidth: '100%',
                        height: 'auto',
                        border: '1px solid #eee',
                        borderRadius: '4px',
                        padding: '8px'
                      }
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ 
                      __html: q.illustration.svg_content 
                    }} />
                  </Box>
                )}
                <Typography variant="body1" gutterBottom>
                  {renderMessage(q.question)}
                </Typography>
                <RadioGroup className="q_radio_group">
                  {q.options.map((option, i) => (
                    <FormControlLabel
                      key={i}
                      value={String(i)}
                      control={<Radio />}
                      label={renderMessage(option)}
                      disabled
                    />
                  ))}
                </RadioGroup>
                {showAnswers && (
                  <>
                    <Typography variant="body1" color="primary" sx={{ mt: 1 }} className="mcq_answer">
                      Correct Answer:{" "}
                      {String.fromCharCode(65 + q.correctAnswer)}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      Explanation: {renderMessage(q.reason)}
                    </Typography>
                  </>
                )}
                <Box 
                  sx={{ 
                    position: "absolute", 
                    top: 10, 
                    right: 10,
                  }}
                >
                  <Box 
                    className="assessment-detail-buttons" 
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '3em',
                      padding: '0px',
                      borderRadius: '12px',
                      // border: '1px solid rgba(0, 0, 0, 0.12)',
                      backgroundColor: (theme) => theme.palette.background.paper,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                      '& .MuiIconButton-root': {
                        padding: '8px',
                      },
                      '& .verifyIcon .MuiIconButton-root': {
                        padding: '8px',
                      }
                    }}
                  >
                    <Tooltip title={verificationStatus[index]?.explanation || ""}>
                      <span className="verifyIcon">
                        <MCQVerification
                          status={verificationStatus[index]?.status}
                          onVerify={() => verifyQuestion(index)}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
                <Box 
                sx={{ 
                  position: "absolute", 
                  bottom: 15, 
                  right: 15,
                  zIndex: 1
                }}
              >
                <Tooltip title="Remove question">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => handleRemoveQuestion(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              </CardContent>
            </Card>
          ))}

          <Box mt={2} display="flex" gap={2}>
            {showSaveButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
              >
                Save and Share
              </Button>
            )}
            {showPrintButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MCQGenerator;