import React, { useState, useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Collapse,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Create as CreateIcon,
  List as ListIcon,
  Info as InfoIcon,
  Feedback as FeedbackIcon,
  Dashboard as DashboardIcon,
  AdminPanelSettings as AdminIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  CloudUpload as CloudUploadIcon,
  Science as ScienceIcon,
  Slideshow as SlideshowIcon,
  Group as GroupIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import { ThemeContext } from "./ThemeContext";

const drawerWidth = 240;

const SideMenu = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { darkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const [experimentsOpen, setExperimentsOpen] = useState(false);

  const handleExperimentsClick = () => {
    setExperimentsOpen(!experimentsOpen);
  };

  // Update menu items based on authentication
  const menuItems = [
    ...(user
      ? [
          { label: "Create", path: "/select", icon: <CreateIcon /> },
          { label: "Assessment Gallery", path: "/assessment/listall", icon: <ListIcon /> },
          { label: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
        ]
      : []),
    // { label: "Home", path: "/", icon: <HomeIcon /> },
    { label: "Showcase", path: "/showcase", icon: <SlideshowIcon /> },
    { label: "Team", path: "/team", icon: <GroupIcon /> },
    { label: "Disclaimer", path: "/disclaimer", icon: <InfoIcon /> },
    { label: "Feedback", path: "/feedback", icon: <FeedbackIcon /> },
    ...(user?.group === "admin"
      ? [
          { label: "Admin Center", path: "/admin", icon: <AdminIcon /> },
          {
            label: "Experiments",
            icon: <ScienceIcon />,
            isExpandable: true,
            subItems: [
              {
                label: "Simple Form Flow",
                path: "/experiments/simple-form-flow",
              },
            ],
          },
        ]
      : []),
  ];

  const handleMenuItemClick = (path) => {
    if (path) {
      navigate(path);
      if (!user) {
        // Only close drawer for non-authenticated users
        handleDrawerClose();
      }
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: darkMode ? "#151515" : "#ffffff",
          color: darkMode ? "#ffffff" : "#000000",
          overflowX: 'hidden',
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          [theme.breakpoints.up('sm')]: {
            width: open ? drawerWidth : theme.spacing(7),
          },
        },
      }}
      variant={user ? "permanent" : "temporary"}
      anchor="left"
      open={user ? true : open}
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
          justifyContent: open ? "flex-end" : "center",
        }}
      >
        {user && (
          <IconButton 
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {open ? <ChevronLeftIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} /> : 
                    <MenuIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />}
          </IconButton>
        )}
        {!user && (
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />
          </IconButton>
        )}
      </Box>
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.label}>
            <ListItem
              button
              onClick={
                item.isExpandable
                  ? handleExperimentsClick
                  : () => handleMenuItemClick(item.path)
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon 
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: darkMode ? "#ffffff" : "#000000"
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.label} 
                sx={{ opacity: open ? 1 : 0 }}
              />
              {item.isExpandable && open && (
                <>{experimentsOpen ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItem>
            {item.isExpandable && (
              <Collapse in={experimentsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem) => (
                    <ListItem
                      button
                      key={subItem.label}
                      onClick={() => handleMenuItemClick(subItem.path)}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={subItem.label} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
        {user ? (
          <ListItem 
            button 
            onClick={logout}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon 
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: darkMode ? "#ffffff" : "#000000"
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText 
              primary="Logout" 
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItem>
        ) : (
          <ListItem 
            button 
            onClick={() => handleMenuItemClick("/login")}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon 
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: darkMode ? "#ffffff" : "#000000"
              }}
            >
              <LoginIcon />
            </ListItemIcon>
            <ListItemText 
              primary="Login" 
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideMenu;
