import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import config from "../config";
import MCQCustomizationModal from "./MCQCustomizationModal";
import MCQVerification from "./MCQVerification";

import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedIcon from '@mui/icons-material/Verified';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

function SortableQuestion({ question, index, children, isGridView }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: question.id || `question-${index}` });

  if (!isGridView) {
    return (
      <Card
        sx={{
          mb: 2,
          height: 'auto',
          overflow: 'auto',
          position: 'relative',
          '&:hover': {
            boxShadow: 3,
          }
        }}
      >
        {children}
      </Card>
    );
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Card
        sx={{
          height: '200px',
          overflow: 'hidden',
          position: 'relative',
          '&:hover': {
            boxShadow: 3,
          }
        }}
      >
        <Box
          {...attributes}
          {...listeners}
          sx={{
            position: 'absolute',
            top: 5,
            left: 5,
            zIndex: 2,
            cursor: 'grab',
            '&:active': {
              cursor: 'grabbing',
            },
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            }
          }}
        >
          <DragIndicatorIcon fontSize="small" />
        </Box>
        
        {children}
      </Card>
    </div>
  );
}

function MCQDetail() {
  const [mcqData, setMcqData] = useState({ title: "", questions: [] });
  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [showAnswers, setShowAnswers] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [customizingQuestions, setCustomizingQuestions] = useState(new Set());
  const [isGridView, setIsGridView] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const getQuestionId = useCallback((index) => {
    const question = mcqData.questions[index];
    return question.id || `question-${index}`;
  }, [mcqData.questions]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchMCQ = async () => {
      try {
        const response = await fetch(`${config.webUrl}/mcqshared/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch MCQ data");
        }
        const data = await response.json();
        setMcqData(data);
      } catch (error) {
        console.error("Error fetching MCQ:", error);
        setSnackbarMessage("Failed to load MCQ data");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchMCQ();
  }, [id]);

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={{
          p: ({ children }) => (
            <Typography 
              component="div" 
              variant="body1" 
              sx={{ mb: 0 }}
            >
              {children}
            </Typography>
          ),
          h3: ({ children }) => (
            <Typography 
              component="div" 
              variant="h6" 
              gutterBottom
            >
              {children}
            </Typography>
          ),
          li: ({ children }) => (
            <Typography 
              component="li" 
              sx={{ mb: 1 }}
            >
              {children}
            </Typography>
          ),
          ul: ({ children }) => (
            <Box 
              component="ul" 
              sx={{ pl: 2, mb: 1 }}
            >
              {children}
            </Box>
          ),
          ol: ({ children }) => (
            <Box 
              component="ol" 
              sx={{ pl: 2, mb: 1 }}
            >
              {children}
            </Box>
          ),
          code: ({ children }) => (
            <Typography 
              component="code" 
              sx={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.08)', 
                borderRadius: 1,
                px: 0.5,
                py: 0.25,
                fontFamily: 'monospace'
              }}
            >
              {children}
            </Typography>
          ),
          blockquote: ({ children }) => (
            <Box 
              component="blockquote" 
              sx={{ 
                borderLeft: 4,
                borderColor: 'grey.300',
                pl: 2,
                py: 1,
                my: 1
              }}
            >
              {children}
            </Box>
          )
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  const handleValidateMCQs = async () => {
    for (let i = 0; i < mcqData.questions.length; i++) {
      await verifyQuestion(i);
    }
  };

  const verifyQuestion = async (index) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [index]: { status: "verifying" },
    }));
    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: mcqData.questions[index].question,
          selected_answer:
            mcqData.questions[index].options[
              mcqData.questions[index].correctAnswer
            ],
        }),
      });
      const data = await response.json();
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: data.result,
          explanation: data.explanation,
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: "re-verify",
          explanation:
            "An error occurred during verification. Please try again.",
        },
      }));
    }
  };

  const handleCustomization = async (customizationData) => {
    try {
      setCustomizingQuestions((prev) => new Set(prev).add(selectedQuestionId));

      const response = await fetch(`${config.webUrl}/mcq/customize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mcqId: id,
          questionId: selectedQuestionId,
          customizationData,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to customize question");
      }

      const data = await response.json();

      // Update only the specific question in the state
      setMcqData((prevData) => ({
        ...prevData,
        questions: prevData.questions.map((q) =>
          q.id === selectedQuestionId ? data.question : q
        ),
      }));

      setSnackbarMessage("Question customized successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error customizing question:", error);
      setSnackbarMessage(`Failed to customize question: ${error.message}`);
      setSnackbarOpen(true);
    } finally {
      setCustomizingQuestions((prev) => {
        const newSet = new Set(prev);
        newSet.delete(selectedQuestionId);
        return newSet;
      });
      setCustomizationModalOpen(false);
    }
  };

  const openCustomizationModal = (questionId) => {
    setSelectedQuestionId(questionId);
    setCustomizationModalOpen(true);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print MCQ</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .question { margin-bottom: 20px; }
            .options { margin-left: 20px; }
            .reason { font-style: italic; color: #666; }
          </style>
        </head>
        <body>
          <h1>${mcqData.title || "Multiple Choice Questions"}</h1>
          ${mcqData.questions
            .map(
              (q, index) => `
              <div class="question">
                <h3>Question ${index + 1}</h3>
                <p>${q.question}</p>
                <div class="options">
                  ${q.options
                    .map(
                      (option, i) => `
                      <p>${String.fromCharCode(65 + i)}. ${option}</p>
                    `
                    )
                    .join("")}
                </div>
                <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                  65 + q.correctAnswer
                )}</p>
                <p class="reason"><strong>Explanation:</strong> ${q.reason}</p>
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const handleRemoveQuestion = async (index) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}/remove-question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          questionIndex: index,
          isShared: true // This indicates we're working with a shared MCQ
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to remove question');
      }

      // Update local state
      setMcqData(prevData => ({
        ...prevData,
        questions: prevData.questions.filter((_, i) => i !== index)
      }));
      
      setSnackbarMessage('Question removed successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error removing question:', error);
      setSnackbarMessage('Failed to remove question');
      setSnackbarOpen(true);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    
    if (!over || active.id === over.id) return;

    setMcqData((prev) => {
      const oldIndex = prev.questions.findIndex(q => (q.id || `question-${prev.questions.indexOf(q)}`) === active.id);
      const newIndex = prev.questions.findIndex(q => (q.id || `question-${prev.questions.indexOf(q)}`) === over.id);
      
      return {
        ...prev,
        questions: arrayMove(prev.questions, oldIndex, newIndex),
      };
    });
    setHasChanges(true);
  };

  const handleSaveReorder = async (saveAsNew = false) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}/reorder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          questions: mcqData.questions,
          saveAsNew,
        }),
      });

      if (!response.ok) throw new Error('Failed to save reordered questions');

      const result = await response.json();
      if (saveAsNew && result.newId) {
        navigate(`/mcq/${result.newId}`);
      }

      setHasChanges(false);
      setSnackbarMessage('Questions reordered successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving reorder:', error);
      setSnackbarMessage('Failed to save reordered questions');
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading MCQ data...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        style={{ marginBottom: "20px" }}
      >
        Back to List
      </Button>

      <Typography variant="h4" gutterBottom>
        {mcqData.title || "Multiple Choice Questions"}
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Total Questions: {mcqData.questions.length}
      </Typography>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" gap={2}>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print
          </Button>
          {hasChanges && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveReorder(false)}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSaveReorder(true)}
              >
                Save as New
              </Button>
            </>
          )}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleValidateMCQs}
            style={{ marginRight: "10px" }}
          >
            Validate MCQs
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowAnswers(!showAnswers)}
            startIcon={showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />}
          >
            {showAnswers ? "Hide Answers" : "Show Answers"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsGridView(!isGridView)}
            startIcon={isGridView ? <ViewListIcon /> : <GridViewIcon />}
            sx={{ ml: 1 }}
          >
            {isGridView ? "List View" : "Rearrange"}
          </Button>
        </Box>
      </Box>

      {isGridView ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={mcqData.questions.map((q, i) => getQuestionId(i))}
            strategy={rectSortingStrategy}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
                gap: 2,
                alignItems: 'start',
                width: '100%',
                '& > *': {
                  width: '100%',
                },
              }}
            >
              {mcqData.questions.map((question, index) => (
                <SortableQuestion
                  key={getQuestionId(index)}
                  question={question}
                  index={index}
                  isGridView={true}
                >
                  <CardContent>
                    <Box sx={{ padding: '8px 36px 8px 12px'}}>
                      <Box 
                        sx={{ 
                          position: "absolute", 
                          top: 10, 
                          right: 10,
                        }}
                      >
                        <Box 
                          className="mcq-tools-buttons" 
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '3em',
                            padding: '0px',
                            borderRadius: '12px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            backgroundColor: (theme) => theme.palette.background.paper,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            '& .MuiIconButton-root': {
                              padding: '8px',
                            },
                            '& .verifyIcon .MuiIconButton-root': {
                              padding: '8px',
                            }
                          }}
                        >
                          <Tooltip title="Customize Question">
                            <IconButton
                              size="small"
                              onClick={() => openCustomizationModal(question.id)}
                              disabled={customizingQuestions.has(question.id)}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={verificationStatus[index]?.explanation || "Verify question"}>
                            <span className="verifyIcon">
                              <MCQVerification
                                status={verificationStatus[index]?.status}
                                onVerify={() => verifyQuestion(index)}
                              />
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Typography variant="h6" gutterBottom>
                        Question {index + 1} of {mcqData.questions.length}
                      </Typography>

                      <Typography variant="body1" gutterBottom sx={{ fontSize: '0.7rem'}}>
                        {renderMessage(question.question)}
                      </Typography>

                      {/* <RadioGroup className="q_radio_group">
                        {question.options.map((option, i) => (
                          <FormControlLabel
                            key={i}
                            value={String(i)}
                            control={<Radio />}
                            label={renderMessage(option)}
                          />
                        ))}
                      </RadioGroup> */}

                      {showAnswers && (
                        <>
                          <Typography
                            variant="body1"
                            className="mcq_answer"
                            color="primary"
                            sx={{ mt: 1 }}
                          >
                            Correct Answer: {String.fromCharCode(65 + question.correctAnswer)}
                          </Typography>
                          <Divider sx={{ my: 1 }} />
                          <Typography variant="body2" color="text.secondary">
                            Explanation: {renderMessage(question.reason)}
                          </Typography>
                        </>
                      )}

                      <Box 
                        sx={{ 
                          position: "absolute", 
                          bottom: 15, 
                          right: 15,
                          zIndex: 1
                        }}
                      >
                        <Tooltip title="Remove question">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleRemoveQuestion(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </CardContent>
                </SortableQuestion>
              ))}
            </Box>
          </SortableContext>
        </DndContext>
      ) : (
        <Box>
          {mcqData.questions.map((question, index) => (
            <SortableQuestion
              key={getQuestionId(index)}
              question={question}
              index={index}
              isGridView={false}
            >
              <CardContent>
                <Box sx={{ padding: '8px 36px 8px 12px' }}>
                  <Box 
                    sx={{ 
                      position: "absolute", 
                      top: 10, 
                      right: 10,
                    }}
                  >
                    <Box 
                      className="mcq-tools-buttons" 
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3em',
                        padding: '0px',
                        borderRadius: '12px',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        backgroundColor: (theme) => theme.palette.background.paper,
                        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                        '& .MuiIconButton-root': {
                          padding: '8px',
                        },
                        '& .verifyIcon .MuiIconButton-root': {
                          padding: '8px',
                        }
                      }}
                    >
                      <Tooltip title="Customize Question">
                        <IconButton
                          size="small"
                          onClick={() => openCustomizationModal(question.id)}
                          disabled={customizingQuestions.has(question.id)}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={verificationStatus[index]?.explanation || "Verify question"}>
                        <span className="verifyIcon">
                          <MCQVerification
                            status={verificationStatus[index]?.status}
                            onVerify={() => verifyQuestion(index)}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>

                  <Typography variant="h6" gutterBottom>
                    Question {index + 1} of {mcqData.questions.length}
                  </Typography>

                  <Typography variant="body1" gutterBottom>
                    {renderMessage(question.question)}
                  </Typography>

                  <RadioGroup className="q_radio_group">
                    {question.options.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        value={String(i)}
                        control={<Radio />}
                        label={renderMessage(option)}
                      />
                    ))}
                  </RadioGroup>

                  {showAnswers && (
                    <>
                      <Typography
                        variant="body1"
                        className="mcq_answer"
                        color="primary"
                        sx={{ mt: 1 }}
                      >
                        Correct Answer: {String.fromCharCode(65 + question.correctAnswer)}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2" color="text.secondary">
                        Explanation: {renderMessage(question.reason)}
                      </Typography>
                    </>
                  )}

                  <Box 
                    sx={{ 
                      position: "absolute", 
                      bottom: 15, 
                      right: 15,
                      zIndex: 1
                    }}
                  >
                    <Tooltip title="Remove question">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleRemoveQuestion(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </CardContent>
            </SortableQuestion>
          ))}
        </Box>
      )}

      <MCQCustomizationModal
        open={customizationModalOpen}
        onClose={() => setCustomizationModalOpen(false)}
        onSubmit={handleCustomization}
        questionId={selectedQuestionId}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default MCQDetail;
