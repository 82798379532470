import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import {
  Memory,
  Group,
  CheckCircle,
  School,
  TouchApp,
  TrendingUp,
  Build,
  Tune,
  LibraryBooks,
  Assessment,
  Category,
  ControlPoint,
  Sync,
  Layers,
  Style,
  QuestionAnswer,
} from "@mui/icons-material";

const keyFeatures = [
  {
    title: "Multi-LLM System",
    description:
      "Harness the power of multiple advanced language models to generate unique and engaging assessments tailored to your learning objectives.",
    icon: <Memory fontSize="large" />,
  },
  {
    title: "Multi-Agent Approach",
    description:
      "Benefit from a collaborative multi-agent approach that ensures the creation of comprehensive and well-structured assessments.",
    icon: <Group fontSize="large" />,
  },
  {
    title: "Alignment with Teaching Objectives",
    description:
      "Seamlessly align your assessments with your specific teaching objectives, ensuring that the generated content is relevant and meaningful.",
    icon: <CheckCircle fontSize="large" />,
  },
  {
    title: "Educational Best Practices",
    description:
      "The application incorporates educational best practices to create assessments that effectively measure student learning and foster engagement.",
    icon: <School fontSize="large" />,
  },
  {
    title: "Streamlined Interface",
    description:
      "Enjoy a user-friendly interface that simplifies the process of creating assessments, saving you time and effort.",
    icon: <TouchApp fontSize="large" />,
  },
  {
    title: "Enhance Learning Outcomes",
    description:
      "Educators can create assessments that drive student achievement and improve overall learning outcomes.",
    icon: <TrendingUp fontSize="large" />,
  },
];

const functionalities = [
  {
    title: "Effortless Assessment Creation",
    description:
      "Create assessments in just three clicks, saving you valuable time and streamlining the process.",
    icon: <Build fontSize="large" />,
  },
  {
    title: "Fully Customizable",
    description:
      "Tailor your assessments to your specific needs with a wide range of customization options.",
    icon: <Tune fontSize="large" />,
  },
  {
    title: "Multi-Disciplinary Support",
    description:
      "Create assessments for various disciplines, ensuring versatility and adaptability across different subjects.",
    icon: <LibraryBooks fontSize="large" />,
  },
  {
    title: "Formative and Summative Assessments",
    description:
      "Design assessments for both formative and summative purposes, catering to different stages of the learning process.",
    icon: <Assessment fontSize="large" />,
  },
  {
    title: "Learning Taxonomies Integration",
    description:
      "Incorporate learning taxonomies into your assessments, ensuring alignment with educational frameworks and objectives.",
    icon: <Category fontSize="large" />,
  },
  {
    title: "Alternative Assessment Generation",
    description:
      "Generate alternative assessments based on existing or shared assessments, providing fresh perspectives and variations.",
    icon: <Sync fontSize="large" />,
  },
  {
    title: "Multiple Learning Outcomes",
    description:
      "Style your assessments to target multiple learning outcomes, ensuring comprehensive evaluation of student knowledge and skills.",
    icon: <Layers fontSize="large" />,
  },
  {
    title: "Diverse Assessment Styles",
    description:
      "Create a variety of assessment styles, including written, quizzes, oral, problem-based, and more, to cater to different learning preferences and objectives.",
    icon: <Style fontSize="large" />,
  },
  {
    title: "Customized MCQs generation",
    description:
      "Generate multiple-choice questions (MCQs) and short-form questions based on learning objectives, providing a balanced mix of assessment formats.",
    icon: <QuestionAnswer fontSize="large" />,
  },
];

function FeatureItem({ title, description, icon }) {
  return (
    <Grid item xs={12} md={4}>
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={2}
      >
        <Box mb={2} color="primary.main">
          {icon}
        </Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Grid>
  );
}

function Features() {
  return (
    <Box sx={{ maxWidth: "80%", margin: "0 auto", py: 4 }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Key Features */}
        <Grid item xs={12} align="center">
          <Typography variant="h3" gutterBottom>
            Key Features
          </Typography>
        </Grid>
        {keyFeatures.map((feature, index) => (
          <FeatureItem
            key={`key-feature-${index}`}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}

        {/* Functionalities */}
        <Grid item xs={12} align="center">
          <Typography variant="h3" gutterBottom>
            Functionalities
          </Typography>
        </Grid>
        {functionalities.map((feature, index) => (
          <FeatureItem
            key={`functionality-${index}`}
            title={feature.title}
            description={feature.description}
            icon={feature.icon}
          />
        ))}
      </Grid>
    </Box>
  );
}

export default Features;
