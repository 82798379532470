import React, { useState, useEffect, useRef } from 'react';
import { Drawer, TextField, Button, List, ListItem, ListItemText, Typography, Box, CircularProgress, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';
import config from '../config';
import CloseIcon from '@mui/icons-material/Close';
import DOMPurify from 'dompurify';
import rehypeRaw from 'rehype-raw';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ChatDrawer = ({ open, onClose, assessmentId, assessmentContent }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (open && assessmentContent && messages.length === 0) {
      initializeChat();
    }
  }, [open, assessmentContent]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const initializeChat = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.webUrl}/api/chat/${assessmentId}/initialize`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ assessmentContent }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessages([
          { role: 'system', content: 'I am you assessment sidekick. Feel free to ask any questions about it.' },
          { role: 'assistant', content: data.response }
        ]);
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
      setMessages([{ role: 'system', content: 'Failed to load assessment. Please try again.' }]);
    }
    setLoading(false);
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    setLoading(true);
    try {
      setMessages(prevMessages => [...prevMessages, { role: 'user', content: input }]);
      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: '', isLoading: true }]);
      
      const response = await fetch(`${config.webUrl}/api/chat/${assessmentId}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: input }),
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(prevMessages => [
          ...prevMessages.slice(0, -1),
          { role: 'assistant', content: data.response }
        ]);
        setInput('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => prevMessages.slice(0, -1));
    }
    setLoading(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
        components={{
          p: ({ node, ...props }) => <Typography paragraph {...props} />,
          h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
          li: ({ node, ...props }) => <li style={{ marginBottom: '10px' }} {...props} />,
          div: ({ node, className, children, ...props }) => {
            const baseProps = {
              ...props,
              className: className
            };

            if (className === 'suggestions') {
              return (
                <Box 
                  {...baseProps}
                  sx={{
                    backgroundColor: '#f5f5f5',
                    padding: 2,
                    borderLeft: '4px solid #1976d2',
                    marginY: 2,
                    borderRadius: 1,
                    position: 'relative',
                    '&:hover .implement-button': {
                      opacity: 1,
                    }
                  }}
                >
                  {children}
                  <Box
                    className="implement-button"
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                      bgcolor: 'rgba(255, 255, 255, 0.9)',
                      padding: '4px 8px',
                      borderRadius: 1,
                      '&:hover': {
                        bgcolor: 'rgba(25, 118, 210, 0.1)',
                      }
                    }}
                    onClick={() => {
                      // Handle implementation click
                      console.log('Implement:', children);
                    }}
                  >
                    <AddCircleOutlineIcon color="primary" fontSize="small" />
                    <Typography
                      variant="caption"
                      color="primary"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Implement
                    </Typography>
                  </Box>
                </Box>
              );
            }
            return <div {...baseProps}>{children}</div>;
          },
          html: ({ node, ...props }) => {
            const sanitizedHtml = DOMPurify.sanitize(node.value);
            return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
          }
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      variant="persistent"
      sx={{ width: 300, flexShrink: 0, '& .MuiDrawer-paper': { width: "25%", minWidth: 400 } }}
    >
      <Box sx={{ height: 'calc(100% - 64px)', display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider'
        }}>
          <Typography variant="h7">Assessment Guide</Typography>
          <IconButton onClick={onClose} aria-label="close chat">
            <CloseIcon />
          </IconButton>
        </Box>
        <List sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
          {messages.map((msg, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={msg.role === 'user' ? 'You' : msg.role === 'system' ? 'System' : 'AP'}
                secondary={
                  msg.isLoading ? (
                    <Box display="flex" alignItems="center">
                      <CircularProgress size={20} sx={{ mr: 1 }} />
                      Thinking...
                    </Box>
                  ) : (
                    <Box mt={2}>
                      {renderMessage(msg.content)}
                    </Box>
                  )
                }
                primaryTypographyProps={{ fontWeight: 'bold' }}
                secondaryTypographyProps={{ component: 'div' }}
              />
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
        <Box sx={{ p: 2, display: 'flex' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Ask about the assessment..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !loading && sendMessage()}
            disabled={loading}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={sendMessage}
                  disabled={loading}
                  sx={{ ml: 1, minWidth: 10 }}
                >
                </Button>
              ),
            }}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default ChatDrawer;