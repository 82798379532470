import { useState, useEffect, createContext, useContext } from "react";
import { Navigate } from "react-router-dom";
import axios from "../axiosConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          await fetchUser(token);
        } catch (error) {
          console.error("Error fetching user:", error);
          localStorage.removeItem("token");
        }
      }
      setLoading(false);
    };
    checkAuth();
  }, []);

  const fetchUser = async (token) => {
    try {
      const response = await axios.get("/api/users/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      console.log("Attempting login...");
      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);

      console.log("Login URL:", axios.defaults.baseURL + "/api/login");

      const response = await axios.post("/api/login", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Login response:", response);
      localStorage.setItem("token", response.data.access_token);
      await fetchUser(response.data.access_token);
    } catch (error) {
      console.error("Login error:", error);
      if (error.response) {
        console.error("Error response:", error.response);
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      throw error;
    }
  };

  const loginWithGoogle = async (idToken) => {
    try {
      const response = await axios.post("/api/google-login", {
        token: idToken
      });
      localStorage.setItem("token", response.data.access_token);
      await fetchUser(response.data.access_token);
    } catch (error) {
      console.error("Google login error:", error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };

  const createAccount = async (email, password) => {
    try {
      const response = await axios.post('/api/users', {
        email,
        password
      });
      console.log('Create account response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Create account error:', error.response || error);
      throw error;
    }
  };

  const requestPasswordReset = async (email) => {
    try {
      const response = await axios.post("/api/password-reset-request", { email });
      console.log('Reset token:', response.data.token);
      return response.data;
    } catch (error) {
      console.error('Password reset request error:', error);
      if (error.response?.status === 422) {
        throw new Error('Invalid email format');
      }
      throw error;
    }
  };

  const resetPassword = async (token, newPassword) => {
    try {
      const response = await axios.post("/api/password-reset", {
        token: token,
        new_password: newPassword
      });
      return response.data;
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  };

  const addUser = async (newUser) => {
    await axios.post("/api/users", newUser, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
  };

  const deleteUser = async (userId) => {
    await axios.delete(`/api/users/${userId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
  };

  const resetUserPassword = async (userId) => {
    await axios.post(
      `/api/users/${userId}/reset-password`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
  };

  const hasPermission = (requiredGroup) => {
    const groupHierarchy = ["basicuser", "user", "poweruser", "admin"];
    return (
      user &&
      groupHierarchy.indexOf(user.group) >=
        groupHierarchy.indexOf(requiredGroup)
    );
  };

  const changeUserGroup = async (userId, newGroup) => {
    try {
      const response = await axios.put(
        `/api/users/${userId}/group`,
        { group: newGroup },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error changing user group:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        login,
        loginWithGoogle,
        logout,
        createAccount,
        requestPasswordReset,
        resetPassword,
        getUsers,
        addUser,
        deleteUser,
        resetUserPassword,
        changeUserGroup,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const withAuth = (WrappedComponent, requiredGroup = "basicuser") => {
  return (props) => {
    const { user, loading, hasPermission } = useAuth();

    if (loading) {
      return <div>Loading...</div>; // Or a loading spinner
    }

    if (!user) {
      return <Navigate to="/login" />;
    }

    if (!hasPermission(requiredGroup)) {
      return <Navigate to="/unauthorized" />;
    }

    return <WrappedComponent {...props} />;
  };
};
