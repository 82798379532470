import React, { useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Grid,
  Snackbar,
  Container,
  Paper,
  Divider,
  Link,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import config from "../config";
import FeedbackIcon from "@mui/icons-material/Feedback";
import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from "@mui/icons-material/Security";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  maxWidth: "600px",
  margin: "0 auto",
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
}));

function Feedback() {
  const handleNavigate = (url) => {
    window.location.href = url;
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    feedbackType: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required";
    tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ? ""
      : "Email is invalid";
    tempErrors.feedbackType = formData.feedbackType
      ? ""
      : "Please select a feedback type";
    tempErrors.message = formData.message ? "" : "Message is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const response = await fetch(`${config.webUrl}/submit-feedback`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("Failed to submit feedback");
        }

        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
          setFormData({
            name: "",
            email: "",
            feedbackType: "",
            message: "",
          });
        }, 4000);
      } catch (error) {
        console.error("Error submitting feedback:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Feedback
        </Typography>

        <Paper elevation={2} sx={{ p: 4, mt: 4 }} className="papercard">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <FeedbackIcon
                  sx={{ fontSize: 30, mr: 2, color: "primary.main" }}
                />
                <Typography variant="h5" gutterBottom>
                  Share Your Thoughts
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Your feedback helps us improve Assessment Partner. We value your
                input and are committed to enhancing your experience with our
                platform.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <SecurityIcon
                  sx={{ fontSize: 30, mr: 2, color: "primary.main" }}
                />
                <Typography variant="h6" gutterBottom>
                  Privacy Notice
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                We do not collect any personal information beyond what you
                provide in this form, and we do not share any information with
                third parties. Your privacy is important to us.
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          <StyledForm onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              error={Boolean(errors.name)}
              helperText={errors.name}
              required
              disabled={isSubmitting}
            />
            <TextField
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              required
              disabled={isSubmitting}
            />
            <FormControl error={Boolean(errors.feedbackType)}>
              <InputLabel>Feedback Type</InputLabel>
              <Select
                name="feedbackType"
                value={formData.feedbackType}
                onChange={handleChange}
                required
                disabled={isSubmitting}
              >
                <MenuItem value="feedback">Feedback</MenuItem>
                <MenuItem value="general">General</MenuItem>
              </Select>
              {errors.feedbackType && (
                <Typography color="error">{errors.feedbackType}</Typography>
              )}
            </FormControl>
            <TextField
              name="message"
              label="Message"
              multiline
              rows={5}
              value={formData.message}
              onChange={handleChange}
              error={Boolean(errors.message)}
              helperText={errors.message}
              required
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                fontSize: "1.1rem",
                fontWeight: 500,
                borderRadius: 2,
                position: "relative",
              }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      left: "50%",
                      marginLeft: "-12px",
                    }}
                  />
                  Submitting...
                </>
              ) : (
                "Submit Feedback"
              )}
            </Button>
          </StyledForm>

          <Divider sx={{ my: 4 }} />

          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 2,
              }}
            >
              <EmailIcon sx={{ fontSize: 30, mr: 2, color: "primary.main" }} />
              <Typography variant="h6">Get in Touch</Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 4 }}>
              For further inquiries, please email us at{" "}
              <Link
                href="mailto:genai@mcmaster.ca"
                color="primary"
                sx={{ fontWeight: 500 }}
              >
                genai@mcmaster.ca
              </Link>
            </Typography>

            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <Box
                  component="img"
                  src="/macpherson-logo.png"
                  alt="MacPherson Institute"
                  sx={{
                    width: 280,
                    height: "auto",
                    cursor: "pointer",
                    transition: "opacity 0.3s",
                    "&:hover": { opacity: 0.8 },
                  }}
                  onClick={() => handleNavigate("https://mi.mcmaster.ca/")}
                />
              </Grid>
              <Grid item>
                <Box
                  component="img"
                  src="/mcmaster_logo.png"
                  alt="McMaster University"
                  sx={{
                    width: 130,
                    height: "auto",
                    cursor: "pointer",
                    transition: "opacity 0.3s",
                    "&:hover": { opacity: 0.8 },
                  }}
                  onClick={() => handleNavigate("https://mcmaster.ca")}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Snackbar
        open={isSubmitted}
        message="Thank you for your feedback!"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            bgcolor: "success.main",
            fontWeight: 500,
          },
        }}
      />
    </Container>
  );
}

export default Feedback;
