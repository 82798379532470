// ThemeContext.js
import React, { createContext, useState, useMemo, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Comfortaa:300,400,700", "sans-serif"],
  },
});

export const ThemeContext = createContext({
  toggleTheme: () => {},
  darkMode: true,
});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode);
    // Apply a class to the body based on the current theme
    document.body.classList.toggle("light-mode", !darkMode);
  }, [darkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
          background: {
            default: darkMode ? "#121212" : "#f7f7f7",
            paper: darkMode ? "#1E1E1E" : "#ffffff",
            gradient: {
              start: darkMode ? "#1A1A1A" : "#f6f9fc",
              end: darkMode ? "#2D2D2D" : "#eef2f7",
            },
          },
          primary: {
            main: darkMode ? "#90caf9" : "#1976d2",
            light: darkMode ? "#e3f2fd" : "#42a5f5",
            dark: darkMode ? "#42a5f5" : "#1565c0",
          },
          secondary: {
            main: darkMode ? "#ce93d8" : "#9c27b0",
            light: darkMode ? "#f3e5f5" : "#ba68c8",
            dark: darkMode ? "#ab47bc" : "#7b1fa2",
          },
          text: {
            primary: darkMode ? "#ffffff" : "#000000",
            secondary: darkMode ? "#b3b3b3" : "#666666",
          },
        },
        typography: {
          fontFamily: ["Comfortaa", "sans-serif"].join(","),
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem",
                backgroundColor: darkMode ? "#333" : "#222",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: darkMode ? "#1E1E1E" : "#ffffff",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                backgroundColor: "#203d5d",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#162d43",
                },
              },
            },
          },
        },
      }),
    [darkMode]
  );

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider value={{ toggleTheme, darkMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
