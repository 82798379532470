import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  useTheme,
  IconButton,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

const intros = [
  {
    src: "/intros/simple_assessment.apng",
    title: "Simple Assessment generation",
    description: "Create straightforward assessments quickly and efficiently",
  },
  {
    src: "/intros/Assessment_MCQ_generation_light.apng",
    title: "MCQ Generation",
    description:
      "Generate multiple-choice questions based on fields of study, learning outcomes, skill sets, taxonomy, and more.",
  },
  {
    src: "/intros/Assessment_MCQ_customization_dark.apng",
    title: "MCQ Customization",
    description:
      "Customize shared and generated multiple-choice questions. Change question types, tone, and learning outcomes.",
  },
  {
    src: "/intros/rubrik_alternative_assessment_light.apng",
    title: "Alternative Assessment genaration",
    description: "Create and manage alternative assessments with ease",
  },
  {
    src: "/intros/rubrik_edit_assessment.apng",
    title: "Assessment Editing - Change rubrics and text",
    description:
      "Customize and edit assessments. Modify and adjust rubrics and other text using NLP with ease.",
  },
  {
    src: "/intros/Assessment_upload_assessment_dark.apng",
    title: "Create Assessment from uploaded content",
    description:
      "Generate assessments based on uploaded content. Perfect for tailoring assessments to specific course materials.",
  },
  {
    src: "/intros/rubrik_edit_assessment_light.apng",
    title: "Edit Assessment sections with ease",
    description: "Edit assessments in our clean, light interface",
  },
  {
    src: "/intros/rubrik_edit_assessment__inline edit_light.apng",
    title: "Edit/Modify Assessments with NLP",
    description: "Change and adjust assessments with natural language.",
  },
];

function AnimatedImage({ src, alt, isPaused }) {
  const [staticImage, setStaticImage] = useState(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (isFirstLoad) {
      // Create static image from first frame
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        setStaticImage(canvas.toDataURL());
        setIsFirstLoad(false);
      };
    }
  }, [src, isFirstLoad]);

  if (!staticImage) {
    return null; // Wait for static image to be generated
  }

  return (
    <Box
      component="img"
      src={isPaused ? staticImage : src}
      alt={alt}
      sx={{
        width: "100%",
        height: "auto",
        borderRadius: "8px",
        mb: 2,
      }}
    />
  );
}

function IntroShowcase() {
  const theme = useTheme();
  const [pausedStates, setPausedStates] = useState({});

  useEffect(() => {
    // Initialize all animations as paused
    const initial = {};
    intros.forEach((_, index) => {
      initial[index] = true;
    });
    setPausedStates(initial);
  }, []);

  const togglePlayPause = (index) => {
    setPausedStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        py: 4,
        background: theme.palette.background.default,
      }}
    >
      <Container maxWidth="xl">
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            mb: 6,
            // fontWeight: 700,
            // background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            // backgroundClip: "text",
            // WebkitBackgroundClip: "text",
            // color: "transparent",
          }}
        >
          Feature Showcase
        </Typography>

        <Grid container spacing={4}>
          {intros.map((intro, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper
                elevation={4}
                sx={{
                  p: 3,
                  height: "100%",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: theme.shadows[8],
                  },
                  background: theme.palette.background.paper,
                  borderRadius: "16px",
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <AnimatedImage
                    src={intro.src}
                    alt={intro.title}
                    isPaused={pausedStates[index]}
                  />
                  <IconButton
                    onClick={() => togglePlayPause(index)}
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                      },
                      color: "white",
                      zIndex: 1,
                    }}
                  >
                    {pausedStates[index] ? <PlayArrowIcon /> : <PauseIcon />}
                  </IconButton>
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    mb: 1,
                    color: theme.palette.primary.main,
                  }}
                >
                  {intro.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  {intro.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default IntroShowcase;
