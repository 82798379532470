import React, { useContext } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Container,
  useTheme,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SchoolIcon from "@mui/icons-material/School";
import SpeedIcon from "@mui/icons-material/Speed";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { ThemeContext } from "./ThemeContext";

function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { darkMode } = useContext(ThemeContext);

  const handleNavigate = (url) => {
    window.location.href = url;
  };

  const features = [
    {
      icon: (
        <AutoAwesomeIcon
          sx={{ fontSize: 40, color: theme.palette.primary.main }}
        />
      ),
      title: "AI-Powered Generation",
      description:
        "Utilizes a generative AI agentic approach to create tailored assessments aligned with your objectives",
    },
    {
      icon: (
        <SchoolIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      ),
      title: "Educational Best Practices",
      description:
        "Built on proven pedagogical frameworks and teaching methodologies",
    },
    {
      icon: (
        <SpeedIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      ),
      title: "Efficient Creation",
      description:
        "Reduce assessment creation time from hours to minutes while maintaining quality",
    },
  ];

  return (
    <Box
      sx={{
        // minHeight: "100vh",
        // maxHeight: "calc(100vh - 70px)",
      }}
    >
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box
          sx={{
            // minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            py: 4,
            position: "relative",
            pb: 0,
          }}
        >
          <img
            src="/logo.png"
            alt="Logo"
            width="250"
            style={{ marginBottom: "16px" }}
          />

          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2.5rem", md: "4.5rem" },
              fontWeight: 800,
              mb: 3,
              // background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              // color: "transparent",
              // textShadow: darkMode
              //   ? '0px 2px 4px rgba(0,0,0,0.5)'
              //   : '0px 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            Assessment Partner
          </Typography>

          <Typography
            variant="h5"
            sx={{
              maxWidth: "800px",
              mb: 3,
              color: theme.palette.text.secondary,
              lineHeight: 1.8,
              fontSize: { xs: "1.1rem", md: "1.3rem" },
            }}
          >
            Transform your assessment creation process with our AI-powered
            platform. Designed for educators, by educators, it leverages sound
            pedagogical methods to help you craft engaging and effective
            assessments that enhance learning outcomes.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              maxWidth: "800px",
              mb: 6,
              color: theme.palette.text.secondary,
              lineHeight: 1.8,
              fontSize: { xs: "0.8.rem", md: "1.0rem" },
            }}
          >
            Currently in beta testing, we value your{" "}
            <a href="/feedback" style={{ color: theme.palette.primary.main }}>
              feedback
            </a>{" "}
            and insights to create a transformative tool for educators.
          </Typography>

          <Box
            sx={{
              mb: 6,
              display: "flex",
              gap: 3,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/select")}
              startIcon={<RocketLaunchIcon />}
              sx={{
                py: 2.5,
                px: 6,
                fontSize: "1.2rem",
                borderRadius: "50px",
                textTransform: "none",
                // background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                boxShadow: darkMode
                  ? "0 4px 15px rgba(0,0,0,0.5)"
                  : "0 4px 15px rgba(25,118,210,0.3)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: darkMode
                    ? "0 6px 20px rgba(0,0,0,0.6)"
                    : "0 6px 20px rgba(25,118,210,0.4)",
                },
              }}
            >
              Access Edu Workspace
            </Button>
          </Box>

          {/* Logos Section */}
          <Box
            sx={{
              py: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 4,
            }}
          >
            <Box
              component="img"
              src="/macpherson-logo.png"
              alt="MacPherson Institute"
              sx={{
                width: 420,
                height: "auto",
                cursor: "pointer",
                opacity: 0.9,
                transition: "all 0.3s ease",
                filter: darkMode
                  ? "grayscale(60%) brightness(0.8) invert(1)"
                  : "grayscale(60%)",
                "&:hover": {
                  opacity: 1,
                  filter: darkMode
                    ? "grayscale(0%) brightness(0.8)"
                    : "grayscale(0%)",
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleNavigate("https://mi.mcmaster.ca/")}
            />
            <Box
              component="img"
              src="/mcmaster_logo.png"
              alt="McMaster University"
              sx={{
                width: 230,
                height: "auto",
                cursor: "pointer",
                opacity: 0.9,
                transition: "all 0.3s ease",
                filter: darkMode
                  ? "grayscale(60%) brightness(0.8) invert(1)"
                  : "grayscale(60%)",
                "&:hover": {
                  opacity: 1,
                  filter: darkMode
                    ? "grayscale(0%) brightness(0.8)"
                    : "grayscale(0%)",
                  transform: "scale(1.05)",
                },
              }}
              onClick={() => handleNavigate("https://mcmaster.ca")}
            />
          </Box>

          {/* Modules Image with Animation */}
          <Paper
            elevation={4}
            sx={{
              p: 2,
              borderRadius: "16px",
            }}
            className="papercard"
          >
            {/* <Box
              component="img"
              src="/Assessment_Partner_modules.png"
              alt="Assessment Partner Modules"
              sx={{
                width: "100%",
                maxWidth: "1000px",
                height: "auto",
                borderRadius: "12px",
              }}
            /> */}
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

export default LandingPage;
